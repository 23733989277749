import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from "react-router-dom";
import './HeaderPage.css';
import { withLocalize } from 'react-localize-redux';
import { GetFirstName } from '../../../_Common/CommonComponent';
import { getMyMealAction } from '../../Actions/Utility.action';
import { MYMEAL_COUNT } from '../../Actions/actionTypes';
import { appConstants } from '../../../_Constants';

import $ from "jquery";
import HeaderMicrosoftPage from '../MicrosoftPage/HeaderMicrosoftPage/HeaderMicrosoftPage';

const HeaderPage = ({ history, headermobileclassName, headerName, setFormData }) => {

    const dispatch = useDispatch()
    const [formData, setFormData1] = useState(setFormData);
    const [userMSPilot, setUserMSPilot] = useState(false)

    const myMealCount = useSelector(state => {
        return state.counters.myMealCount;
    })

    /* Open the sidebar menu for mobile view */
    const openNav = (e) => {
        e.preventDefault()
        document.getElementById("mySidepanel").className = 'sidepanel open';
        document.getElementById("body").className = 'openmenu';
    }

    /* Close sidebar menu for mobile view */
    const closeNav = (e) => {
        e.preventDefault()
        document.getElementById("mySidepanel").className = 'sidepanel close';
        document.getElementById("body").className = '';
    }

    const logout = (e) => {
        e.preventDefault()
        localStorage.clear();
        //history.push('/');
        window.location.href = '/';
    }

    /*const getMyMeal = async() => {
      let userDetailsData = localStorage.getItem("userDetails");
      if(userDetailsData){
        let userDetails = JSON.parse(userDetailsData);
  
        let data = {
          eLoginProvider: userDetails.sLoginProvider,
          sProviderKey: userDetails.sProviderKey
        };
  
        let res = await getMyMealAction(data);
        if(res && res.status == 200 && res.data.length > 0 && res.data[0].Meals){
          dispatch({type:"MYMEAL_COUNT", data: res.data[0].Meals.length});
        }
      }
    }*/

    useEffect(() => {
        if (localStorage.getItem('pagePath')) {
            localStorage.setItem('prvPath', localStorage.getItem('pagePath'))
            localStorage.setItem('pagePath', history.location.pathname)
        } else {
            localStorage.setItem('pagePath', history.location.pathname)
        }
        if (
            localStorage.getItem('prvPath')
            && !history.location.pathname.includes('restaurant')
            && !history.location.pathname.includes('menu-item')
        ) {
            localStorage.removeItem('local_address');
        }
        setFormData1(JSON.parse(localStorage.getItem('userDetails')));

        let userData = JSON.parse(localStorage.getItem('userDetails'));
        //getMyMeal();

        let isMSPilot = JSON.parse(localStorage.getItem('userMSPilot'));

        setUserMSPilot(isMSPilot ? isMSPilot : false)

        if (window.checkStripe) {
            window.checkStripe()
        }
    }, []);

    let isMSPilot = JSON.parse(localStorage.getItem('userMSPilot'));

    useEffect(() => {
        isMSPilot = JSON.parse(localStorage.getItem('userMSPilot'));
        setUserMSPilot(isMSPilot ? isMSPilot : false)
    }, [localStorage.getItem('userMSPilot')])

    return (
        <>
            {
                (isMSPilot || userMSPilot) ? <HeaderMicrosoftPage /> :
                    <header className={headermobileclassName + " site_header menuContainer"}>

                        <div id="mySidepanel" className="sidepanel">
                            <div className="closebtn" onClick={e => closeNav(e)}>×</div>
                            <ul className="mobilememu">
                                {/*<li><div onClick={e => window.location.href = '/landing'}>Home</div></li>
                    <li><div onClick={e => window.location.href = '/dashboard'}>Preferences</div></li>
                    <li><div onClick={e => window.location.href = '/restaurants-list'}>Restaurants</div></li>
                    <li><div onClick={e => window.location.href = '/subscription'}>Subscription</div></li>
                    <li><div onClick={e => window.location.href = '/profile'}>Profile</div></li>
                    <li><div className="logout" onClick={e => logout(e)}>Logout</div></li>
                    <li className="header-navigation-modified"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
                    <li className="header-navigation-modified"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
                    <li className="header-navigation"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
                    <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
                    <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Suggest a Restaurant">Suggest a Restaurant</a></li>
                    <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Offer Feedback">Offer Feedback</a></li>*/}
                                <li className='header-navigation'><div onClick={e => window.location.href = '/landing'}>Home</div></li>
                                <li className='header-navigation'><div onClick={e => window.location.href = '/dashboard'}>EAT: Preference & Restaurants</div></li>
                                <li className='header-navigation'><div onClick={e => cronometer()}>TRACK</div></li>
                                {/*<li><div onClick={e => window.open("https://www.mymenuusa.com/healthy-tips-and-recipes", "_blank")}>GET INSPIRED</div></li>*/}
                                <li className='header-navigation'><div onClick={e => window.open("https://www.mymenuinspired.com", "_blank")}>GET INSPIRED</div></li>
                                <li className='header-navigation'><div onClick={e => window.open("https://www.mymenusuperkids.com", "_blank")}>HEALTHY KIDS</div></li>

                                {/* ++++ Menu without subscription show  ++++ */}
                                <li className="header-navigation-modified"><a href="/subscription">Subscription</a></li>
                                <li><div onClick={e => window.location.href = '/profile'}>Profile</div></li>
                                <li className="header-navigation-modified"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
                                <li className="header-navigation-modified"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
                                <li className="header-navigation-modified"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
                                {/*<li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
                    <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Suggest a Restaurant">Suggest a Restaurant</a></li>
                    <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Offer Feedback">Offer Feedback</a></li>*/}
                                <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Contact Us</div></li>
                                <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Suggest a Restaurant</div></li>

                                <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Offer Feedback</div></li>
                                <li className="header-navigation"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
                                <li className="header-navigation"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
                                <li><div className="logout" onClick={e => logout(e)}>Logout</div></li>
                            </ul>
                            <div className="mobilesidebar_btm">
                                <ul className="header_social_icon">
                                    {/*<li><a href="https://www.facebook.com/HealthyDiningFinder" target="_blank" aria-label="facebook"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
             <li><a href="https://twitter.com/DineOutHealthy" target="_blank" aria-label="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> */}
                                    <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.pinterest.com/MyMenuUSA/" target="_blank" aria-label="pinterest"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
                                    <li><a href="https://instagram.com/mymenuusa" target="_blank" aria-label="instagram"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank" aria-label="facebook"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
                                </ul>
                                <div className="header_logo_wrap text-center">
                                    <img src="/static/img/headerlogo_mmc.png" alt="headerlogo_mm"></img>
                                </div>
                            </div>
                        </div>

                        <div className="container">

                            <div className="row">
                                <div className="col-3 col-sm-3">
                                    <ul className="header_social_icon hide_mobile d-none">
                                        {/*<li><a href="https://twitter.com/DineOutHealthy" target="_blank" aria-label="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> */}
                                        <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
                                        <li><a href="http://www.pinterest.com/MyMenuUSA/" target="_blank" aria-label="pinterest"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
                                        <li><a href="http://instagram.com/mymenuusa" target="_blank" aria-label="instagram"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
                                        <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank" aria-label="facebook"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
                                    </ul>
                                    <button className="openbtn" onClick={e => openNav(e)}><img src="/static/img/toggle.png"></img></button>
                                </div>
                                <div className="col-6 col-sm-6">

                                    <div className="header_logo_wrap text-center">
                                        <div title="MyMenu" className="header-profile"><img src="/static/img/headerlogo_mmc.png" alt="headerlogo_mm"></img></div>
                                    </div>
                                    <div className="mobile_logowrap">
                                        {headerName === 'dashboard' ?
                                            <div className="header_logo_wrap text-center">
                                                <div title="MyMenu" className="header-profile"><img src="/static/img/headerlogo_mm.png" alt="headerlogo_mm"></img></div>
                                            </div>
                                            :
                                            <div title="MyMenu" className="page-name">{headerName}</div>
                                        }
                                    </div>
                                    <div className="header-navigation hide_mobile">
                                        <a className="navigation navigation-concierge" href="/landing">Home</a> | <a className="navigation navigation-preferences" href="/dashboard">Preferences</a> | <a className="navigation navigation-restaurants" href="/restaurants-list">Restaurants</a> | <a className="navigation navigation-profile" href="/profile">Profile</a> | <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a><br />
                                        <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a> | <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Contact Us</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Suggest a Restaurant</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Offer Feedback</a>

                                    </div>
                                    <div className="header-navigation-modified hide_mobile">
                                        <a className="navigation navigation-subscription" href="/subscription">Subscription</a> | <a className="navigation navigation-profile" href="/profile">Profile</a> | <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a><br />
                                        <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a> | <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Contact Us</a>
                                    </div>
                                </div>
                                {/*<div className="col-sm-3">
                 <div className="header_right_sec">
                 <div className="header_greeting">
                    <span className="">
                      <h6 className="prfile_name" >Hello <GetFirstName />!</h6>
                    </span>
                    <span title="My Profile" className="header-profile"  onClick={e=>history.push('/profile')}><img src="/static/img/headeruser.png"></img></span>
                       <span title="MyMeal" className="header-profile header-my-meal"  onClick={e=>history.push('/my-meal')}><img src="/static/img/mymeal.png"></img> ({myMealCount})</span>
                       <span title="Logout" className="header-profile logout"  onClick={e=>logout(e)}><i className="fa fa-sign-out" aria-hidden="true"></i></span>
                 </div>
                          <div className="hide_desktop">
                  { headerName === 'dashboard' &&
                                  <a className="header-profile" onClick={e => window.location.href = '/profile'}><img src="/static/img/headeruser.png"></img></a>
                  }
                              <a className="header-profile header-my-meal" onClick={e => window.location.href = '/my-meal'}><img src="/static/img/mymeal.png"></img> ({myMealCount})</a>
                 </div>

                 </div>
               </div>*/}
                            </div>
                        </div>
                    </header>
            }
        </>
    );
}

function LogOff() {
    localStorage.clear();
    window.location.href = '/';
}

function cronometer() {
    let userDetailsData = JSON.parse(localStorage.getItem('userDetails'));
    var cronometerWindow = window.open('', '_blank');
    cronometerWindow.document.write('Loading TRACK...');
    $.ajax({
        type: "GET",
        url: 'https://nutritrustapi.azurewebsites.net/api/User/CronometerAsync?sEmail=' + userDetailsData.sEmail + '&sHost=' + appConstants.WEB_HOST,
        //url: 'http://localhost:51851/api/User/CronometerAsync?sEmail=' + userDetailsData.sEmail,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
            "Content-Type": "application/json",
            "apikey": 'A3BDF392-1EA2-4A8E-BD67-2B985D4CBD6C',
            "aspnetuserid": userDetailsData.sUserId,
            'crossDomain': true,
        },
        beforeSend: function () {
            $('.actionProcess').css('display', 'block');
        },
        success: function (result) {
            $('.actionProcess').css('display', 'none');
            cronometerWindow.location.href = result.redir;
            //var win = window.open(result.redir, '_blank');
            //if (win == null) {
            //    alert("You must enable pop-ups from MyMenuConcierge to use the TRACK feature.")
            //}
            //else {
            //    win.focus();
            //}

        },
        failure: function () {
            $('.actionProcess').css('display', 'none');
            cronometerWindow.close();
            alert("There was an error loading Track.  Please try again.");
        },
        error: function () {
            $('.actionProcess').css('display', 'none');
            cronometerWindow.close();
            alert("There was an error loading Track.  Please try again.");
        }
    });
};

export default withLocalize(withRouter(HeaderPage));
