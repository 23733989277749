import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';

import './HeaderLoginPage.css';

const HeaderLoginPage = ({ history }) => {

    const [loader, setLoader] = useState('false');

    return (

        <>
            <header>
                <div className="actionProcess" style={{ display: loader ? 'none' : 'block' }}>
                    <img src="/static/img/loader.gif" alt="Loading" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="screen2_inner">
                                <img src="/static/img/landinglogo.png" alt="landinglogo" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>

    );
}
export default withLocalize(withRouter(HeaderLoginPage));
