import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux'
import './FooterPage.css';

const FooterPage = ({ history }) => {

    return (
        <footer>
            {/* <div className="row">
              <div className="col-md-12">
                  <div className="footer_logo_wrap text-center">
                      <a href=""><img src="/static/img/footerlogo.png"></img></a>
                  </div>
                  <table className="table table-dark">
                      <thead>
                          <tr>
                              <th className="text-center footer-th"><a className="footer-links" target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></th>
                              <th className="text-center"><a className="footer-links" target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></th>
                              <th className="text-center"><a className="footer-links" target="_blank" href="mailto:hello@healthydining.biz?subject=Suggest a Restaurant">Suggest a Restaurant</a></th>
                              <th className="text-center"><a className="footer-links" target="_blank" href="mailto:hello@healthydining.biz?subject=Offer Feedback">Offer Feedback</a></th>
                              <th className="text-center"><a className="footer-links" target="_blank" href="https://www.mymenuusa.com">About</a></th>
                          </tr>
                      </thead>
                  </table>
              </div>

          </div> */}


            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-lg-8'>
                        <ul>
                            <li className="header-navigation"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
                            <li className="header-navigation"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
                            <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}><a>Contact Us</a></div></li>
                            {/* <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}><a>Suggest a Restaurant</a></div></li> */}
                            <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}><a>Offer Feedback</a></div></li>
                        </ul>
                    </div>

                    {/* <div className='col-12 col-sm-12 col-lg-4'>
                    <div className='footerSocail'>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.pinterest.com/MyMenuUSA/" target="_blank"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
                            <li><a href="https://instagram.com/mymenuusa" target="_blank"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div> */}

                </div>
            </div>


        </footer>
    );
}

export default withLocalize(withRouter(FooterPage));
